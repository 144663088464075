
import React from "react";

interface AboutProps {
  id: string
  header: string
  text: string
  image: string
}

const About: React.FC<AboutProps> = ({text, header, image, id}) => {
  return (
    <section className="about-section text-center" id={id}>
    <div className="container px-4 px-lg-5">
      <div className="row gx-4 gx-lg-5 justify-content-center">
        <div className="col-lg-8">
          <h2 className="text-white mb-4">{header}</h2>
          <p className="text-white-50">
            {text}
          </p>
        </div>
      </div>
      <img className="img-fluid" src={image} alt="..." />
    </div>
  </section>
  );
}

export default About;