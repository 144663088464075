import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TokenResponse } from '@react-oauth/google';

export interface LoginState {
    googleToken: TokenResponse
}

const initialState: LoginState = {
    googleToken:{
        access_token:'',
        expires_in:0,
        prompt:'',
        scope:'',
        token_type:''
    }
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateGoogleToken: (state, action: PayloadAction<TokenResponse>) => {
        state.googleToken = action.payload
    }
  },
});

export const {updateGoogleToken } = loginSlice.actions;
export const selectGoogleToken = (state: RootState) => state.login.googleToken;


export default loginSlice.reducer;
