
import React from "react";

interface ContactProps {
    id: string
    address: string,
    email: string,
    phone: string
}

interface CardBoxProps {
    name: string,
    msg: string
}

const CardBox: React.FC< CardBoxProps> = ({name,msg}) =>{
    return (
        <div className="card py-4 h-100">
            <div className="card-body text-center">
                <i className="fas fa-map-marked-alt text-primary mb-2"></i>
                <h4 className="text-uppercase m-0">{name}</h4>
                <hr className="my-4 mx-auto" />
                <div className="small text-black-50">{msg}</div>
            </div>
        </div>
    );
};
const Contact: React.FC<ContactProps> = ({ id, address, email, phone }) => {

    return (
        <section className="contact-section bg-black" id={id}>
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5">
                    <div className="col-md-4 mb-3 mb-md-0">
                        <CardBox name="Address" msg={address}/>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <CardBox name="Email" msg={email}/>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <CardBox name="Phone" msg={phone}/>
                    </div>
                </div>
                <div className="social d-flex justify-content-center">
                    <a className="mx-2" href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
        </section>
    );
}

export default Contact;
