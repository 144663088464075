
import React from "react";
import logo from '../images/png/logo_real_visao_no_bg.png';


interface MasterHeaderProps {
  title: string
  subtitle: string
}

const MasterHeader: React.FC<MasterHeaderProps> = ({title,subtitle}) => {


  const navigateToBooking =()=>{
    window.location.replace("https://realvisao.setmore.com");
  }

  return (
    <header className="masthead">
      <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <img className="logo"  src={logo} alt="logo" width="1000" />
            <h2 className="text-white-50 mx-auto mt-2 mb-5">{subtitle}</h2>
            <h3 className="text-white mx-auto my-0 text-uppercase" >

            <button className="button-81" onClick={navigateToBooking}>Agende sua vistoria</button>

            </h3>

          </div>
        </div>
      </div>
    </header>
  );
}

export default MasterHeader;

